import { Menu as UikitMenu } from '@bami-libs/uikit'
import GradientButton from 'components/Button/button'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useAuth from 'hooks/useAuth'
import useI18n from 'hooks/useI18n'
import useTheme from 'hooks/useTheme'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { usePrices } from 'state/price/hooks'
import links from './config'

const Menu: React.FC = (props) => {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const prices = usePrices()
  const location = useLocation()
  const { kaiAccount, getKaiAccount } = useAuth()
  const TranslateString = useI18n()

  if ((document.querySelector('#root>div:first-child>div:last-child') as HTMLDivElement) && isDark) {
    ;(document.querySelector('#root>div:first-child>div:last-child') as HTMLDivElement).style.backgroundColor =
      '#141E30'
  }
  useEffect(() => {
    if (location.pathname === '/') {
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)') as HTMLDivElement
      ).style.background = 'rgba(255, 140, 66,.1)'
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)') as HTMLDivElement
      ).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)') as HTMLDivElement
      ).style.background = 'none'
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(2)') as HTMLDivElement
      ).style.boxShadow = 'none'
    }

    if (location.pathname === '/lending') {
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)') as HTMLDivElement
      ).style.background = 'rgba(255, 140, 66,.1)'
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)') as HTMLDivElement
      ).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)') as HTMLDivElement
      ).style.background = 'none'
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(3)') as HTMLDivElement
      ).style.boxShadow = 'none'
    }

    if (location.pathname === '/converter') {
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)') as HTMLDivElement
      ).style.background = 'rgba(255, 140, 66,.1)'
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)') as HTMLDivElement
      ).style.boxShadow = 'inset 4px 0px 0px rgba(255, 140, 66,1)'
    } else {
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)') as HTMLDivElement
      ).style.background = 'none'
      ;(
        document.querySelector('#root>div nav + div>div:first-child>div:first-child>div:nth-child(4)') as HTMLDivElement
      ).style.boxShadow = 'none'
    }
  }, [location.pathname])

  useEffect(() => {
    if (prices.BAMI) {
      const bamiPrice = parseFloat(prices.BAMI?.toString() || '0').toFixed(4)
      document.title = `Bami - $${bamiPrice}`
    }
  }, [prices])

  return (
    <>
      {/* @ts-ignore */}
      <UikitMenu
        links={links}
        // account={kaiAccount}
        // login={getKaiAccount}
        // logout={() => {
        //   window.location.href = '/'
        // }}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={selectedLanguage?.code || ''}
        langs={allLanguages}
        setLang={setSelectedLanguage}
        cakePriceUsd={parseFloat(prices.BAMI?.toString() || '0')}
        {...props}
      />
      <div style={{ position: 'fixed', top: '14px', right: '-10px', zIndex: 100 }}>
        <GradientButton
          padding="0.5rem 1rem"
          fontSize="0.85rem"
          borderRadius="10px"
          style={{
            maxWidth: '120px',
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
          }}
          onClick={
            !kaiAccount
              ? getKaiAccount
              : () => {
                  window.location.href = '/'
                }
          }
          name={kaiAccount || TranslateString(90059, 'Connect KAI')}
        />
      </div>
    </>
  )
}

export default Menu
