import { connectorLocalStorageKey, ConnectorNames } from '@bami-libs/uikit'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import Web3 from 'web3'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { connectorsByName } from 'connectors'
import useToast from 'hooks/useToast'
import { useCallback, useContext } from 'react'
import { setupNetwork } from 'utils/wallet'
import KaiAccountContext from './KaiAccountContext'

declare global {
  interface Window {
    kardiachain: any
    kaiAccount: any
  }
}

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const { toastError } = useToast()
  const { kaiAccount, setKaiAccount } = useContext(KaiAccountContext)

  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]
    if (connector) {
      activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork()

          if (hasSetup) {
            activate(connector, async (err2: Error) => {
              window.localStorage.removeItem(connectorLocalStorageKey)
              if (err2 instanceof UnsupportedChainIdError) {
                toastError('Unsupported Chain Id', 'Unsupported Chain Id Error. Check your chain Id.')
              } else if (err2 instanceof NoEthereumProviderError || err2 instanceof NoBscProviderError) {
                toastError('Provider Error', 'No provider was found')
              } else if (
                err2 instanceof UserRejectedRequestErrorInjected ||
                err2 instanceof UserRejectedRequestErrorWalletConnect
              ) {
                if (connector instanceof WalletConnectConnector) {
                  const walletConnector = connector as WalletConnectConnector
                  walletConnector.walletConnectProvider = null
                }
                toastError('Authorization Error', 'Please authorize to access your account')
              } else {
                toastError(err2.name, err2.message)
              }
            })
          }
        } else {
          window.localStorage.removeItem(connectorLocalStorageKey)

          if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
            toastError('Provider Error', 'No provider was found')
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            toastError('Authorization Error', 'Please authorize to access your account')
          } else {
            toastError(error.name, error.message)
          }
        }
      })
    } else {
      toastError("Can't find connector", 'The connector config is wrong')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const kardiaWalletEnabled = useCallback(() => {
    if (window.kardiachain) {
      window.web3 = new Web3(window.kardiachain)
      if (window.kardiachain.isKaiWallet) {
        window.kardiachain.enable()
        return true
      }
    }
    return false
  }, [])

  const getKaiAccount = useCallback(async () => {
    if (!kardiaWalletEnabled()) {
      alert('Please install the Kardia Extension Wallet to access.')
      return undefined
    }

    const accounts = await window.web3.eth.getAccounts()
    setKaiAccount(accounts?.[0] || '')
    window.kaiAccount = accounts?.[0] || ''

    return accounts
  }, [kardiaWalletEnabled, setKaiAccount])

  return { login, logout: deactivate, kardiaWalletEnabled, getKaiAccount, kaiAccount }
}

export default useAuth
