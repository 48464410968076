import { Button, Heading, LogoIcon, Text } from '@bami-libs/uikit'
import useI18n from 'hooks/useI18n'
import React from 'react'
import styled from 'styled-components'

import Page from '../components/layout/Page'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`

const ComingSoon = () => {
  const TranslateString = useI18n()

  return (
    <Page>
      <StyledNotFound>
        <LogoIcon width="64px" mb="8px" />
        <h1 style={{ color: '#ff8c42', fontSize: '3rem', marginBottom: '1rem' }}>
          {TranslateString(689, 'Coming Soon...')}
        </h1>
        <Button as="a" href="/" size="sm">
          {TranslateString(999, 'Back Home')}
        </Button>
      </StyledNotFound>
    </Page>
  )
}

export default ComingSoon
