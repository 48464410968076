import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pancakeswap-libs/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSingleCallResult } from 'state/multicall/hooks'
import { LENDING_CONTROLLER_ADDRESS } from 'constants/index'
import { JsonRpcProvider } from '@ethersproject/providers'
import { BigNumber } from 'bignumber.js'
import { kardiaContract, kardiaTransaction } from 'utils/kardiaChain'

import LENDING_CONTROLLER_ABI from '../constants/abis/lending-controller.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { V1_EXCHANGE_ABI, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import useAuth from './useAuth'

export enum ApprovalState {
  INIT_CHECKING,
  APPROVING,
  APPROVED,
  UNKNOWN,
}

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useV1FactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && V1_FACTORY_ADDRESSES[chainId], V1_FACTORY_ABI, false)
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.BSCTESTNET:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}

export function useBridgeContract(contractAddress: string, abi: any): Contract | null {
  return useContract(contractAddress, abi, true)
}

export function useBridgeAllowance(tokenAddress: string, contractAddress: string, account: string) {
  const inputs = useMemo(() => [account, contractAddress], [account, contractAddress])
  const tokenContract = useTokenContract(tokenAddress, true)
  const allowance = useSingleCallResult(tokenContract, 'allowance', inputs).result

  return allowance
}

export function useLendingContract(contractAddress: string, abi: any): Contract | null {
  return useContract(contractAddress, abi, true)
}

export function useLendingControllerContract(): Contract | null {
  const { chainId: activeChainId } = useActiveWeb3React()
  const chainId = activeChainId || process.env.REACT_APP_CHAIN_ID
  // @ts-ignore
  const lendingAddress = LENDING_CONTROLLER_ADDRESS[chainId]

  return useContract(lendingAddress, LENDING_CONTROLLER_ABI)
}

export function useChainID() {
  const { chainId: activeChainId } = useActiveWeb3React()
  return activeChainId || process.env.REACT_APP_CHAIN_ID
}

export function useDipoContract(contractAddress: string, abi: any): Contract | null {
  return useContract(contractAddress, abi, true)
}

export const useKaiTokenBalanceContract = (tokenAddress: string, address?: string | null) => {
  const [contractAmount, setContractAmount] = useState()

  const provider = useMemo(() => {
    return new JsonRpcProvider('https://rpc.kardiachain.io')
  }, [])

  const tokenContract = useMemo(() => new Contract(tokenAddress, ERC20_ABI, provider), [tokenAddress, provider])

  useEffect(() => {
    if (address) {
      tokenContract
        ?.balanceOf?.(address)
        .then((balanceOf) => {
          setContractAmount(balanceOf ? balanceOf.toString() : undefined)
        })
        .catch((error) => {
          throw error
        })
    }
  }, [provider, tokenContract, address])

  if (!address) {
    return '0'
  }

  return contractAmount
}

export const useKaiTokenApproval = (tokenAddress: string, targetAddress: string, approveAmount?: string) => {
  const [initChecking, setInitChecking] = useState<boolean>(true)
  const [approved, setApproved] = useState<boolean>()
  const [approving, setApproving] = useState<boolean>()
  const { kaiAccount } = useAuth()

  // check if we have already approved, call directly to prevent buggy if we on Ethereum
  useEffect(() => {
    try {
      const getKaiAllowance = async () => {
        if (kaiAccount && tokenAddress) {
          kardiaContract.updateAbi(ERC20_ABI)
          const allowance = await kardiaContract
            .invokeContract('allowance', [kaiAccount, targetAddress])
            .call(tokenAddress, {}, 'latest')

          setInitChecking(false)
          if (allowance.toString() !== '0') {
            const bigNumberAllowance = new BigNumber(allowance)
            const bigNumberApproveAmount = new BigNumber(approveAmount || 0)
            if (bigNumberAllowance.comparedTo(bigNumberApproveAmount) >= 0) {
              setApproved(true)
            }
          }
        }
      }
      getKaiAllowance()
    } catch (error) {
      console.log('error', error)
    }
  }, [tokenAddress, approveAmount, targetAddress, kaiAccount])

  const handleApproveKai = useCallback(async () => {
    try {
      kardiaContract.updateAbi(ERC20_ABI)
      const data = await kardiaContract
        .invokeContract('approve', [targetAddress, '99999999999999999999999999'])
        .txData()

      await kardiaTransaction.sendTransactionToExtension(
        {
          to: tokenAddress,
          gasPrice: '1000000000',
          gas: 300000,
          data,
        },
        true
      )

      setTimeout(async () => {
        const allowance = await kardiaContract
          .invokeContract('allowance', [kaiAccount, targetAddress])
          .call(tokenAddress, {}, 'latest')

        if (allowance.toString() !== '0') {
          setApproved(true)
          setApproving(false)
        }
      }, 1500)
    } catch (err) {
      setInitChecking(false)
      setApproved(false)
      setApproving(false)
    }
  }, [kaiAccount, targetAddress, tokenAddress])

  const handleApprove = useCallback(async () => {
    setApproving(true)
    return handleApproveKai()
  }, [handleApproveKai])

  const approvalState = useMemo(() => {
    if (initChecking) {
      return ApprovalState.INIT_CHECKING
    }
    if (approved) {
      return ApprovalState.APPROVED
    }

    if (approving) {
      return ApprovalState.APPROVING
    }

    return ApprovalState.UNKNOWN
  }, [initChecking, approving, approved])

  return {
    approvalState,
    handleApprove,
  }
}
