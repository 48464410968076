import { createContext } from 'react'

interface KaiAccountState {
  kaiAccount?: string | null
  setKaiAccount: React.Dispatch<React.SetStateAction<string>>
}

const defaultKaiAccountState: KaiAccountState = {
  kaiAccount: null,
  setKaiAccount: () => {
    // console.log('init)
  },
}

const KaiAccountContext = createContext(defaultKaiAccountState)

export default KaiAccountContext
