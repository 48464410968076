import { MenuEntry } from '@bami-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    href: 'https://kaidex.io/exchange/0xeD2d01C124A562160a87cAcf59092B6Bf7083D09',
  },
  {
    label: 'Bridge',
    icon: 'BridgeIcon',
    href: '/',
  },
  {
    label: 'Lending',
    icon: 'BankIcon',
    href: '/lending',
  },
  {
    label: 'Migration',
    icon: 'TradeIcon',
    href: '/migration',
  },
  {
    label: '  ',
    icon: 'PoolIcon',
    href: '/',
    calloutClass: 'empty-menu-link',
  },
  {
    label: 'Docs',
    icon: 'MoreIcon',
    href: 'https://bami.gitbook.io/bami/',
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/bamidefi?lang=en',
  },
  {
    label: 'Youtube',
    icon: 'YoutubeIcon',
    href: 'https://www.youtube.com/channel/UCPx70SlwQrQWCcD4sUghr5A',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/bamimoney',
  },
  {
    label: 'Blog',
    icon: 'GroupsIcon',
    href: 'https://blog.bami.money/',
  },
  {
    label: 'Audit by CertiK',
    icon: 'ProtectIcon',
    href: 'https://www.certik.org/projects/bami',
  },
]

export default config
