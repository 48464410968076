import React, { useMemo, useState } from 'react'
import { Flex } from '@bami-libs/uikit'
import styled from 'styled-components'
import { ArrowDown } from 'react-feather'
import BigNumber from 'bignumber.js'

import { BAMI_CONVERTER_KAI, BAMI_V1_TOKEN_KAI } from 'constants/index'
import { useKaiTokenBalanceContract, useKaiTokenApproval, ApprovalState } from 'hooks/useContract'
import useAuth from 'hooks/useAuth'
import Row from 'components/Row'
import BAMI_CONVERTER_ABI from 'constants/abis/KaiConverter.json'
import { kardiaContract, kardiaTransaction } from 'utils/kardiaChain'
import useI18n from 'hooks/useI18n'
import { formatNumber } from 'utils/format'
import GradientButton from 'components/Button/button'
import { BodyWrapper } from '../AppBody'

const PageWrapper = styled(BodyWrapper)`
  max-width: 500px;
  width: 100%;
  padding: 1rem;
  text-align: center;

  .highlight {
    font-size: 0.875rem;
  }

  .top-balance {
    display: flex;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .label {
    text-transform: uppercase;
    color: #999;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .title {
    font-size: 0.875rem;
    font-weight: 500;
  }

  .input-wrapper {
    margin-bottom: 2rem;
    margin-top: 1rem;

    input {
      border-radius: 2rem;
      border: 1px solid black;
      width: 100%;
      height: 40px;
      padding: 0 1rem;
      margin-right: 0.5rem;

      &:focus {
        outline: none;
      }
    }
  }

  button {
    flex: 1;
    margin-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 2rem;
  }
`

const ConverterPage = () => {
  const [loading, setLoading] = useState(false)
  const { kaiAccount } = useAuth()
  const [amount, setAmount] = useState<number>()
  const balance = useKaiTokenBalanceContract(BAMI_V1_TOKEN_KAI, kaiAccount)
  const balanceBN = new BigNumber(balance || 0).div(new BigNumber(10).pow(18))
  const { handleApprove, approvalState } = useKaiTokenApproval(
    BAMI_V1_TOKEN_KAI,
    BAMI_CONVERTER_KAI,
    amount?.toString() || '0'
  )
  const _t = useI18n()

  const handleSwap = async () => {
    try {
      if (!amount) {
        return
      }
      setLoading(true)
      const numberOfToken = +(amount || 0)

      kardiaContract.updateAbi(BAMI_CONVERTER_ABI)
      const data = await kardiaContract
        .invokeContract('convert', [new BigNumber(numberOfToken * 10 ** 18).toFixed(0)])
        .txData()

      const tx = await kardiaTransaction.sendTransactionToExtension(
        {
          gasPrice: '1000000000',
          gas: 100000,
          to: BAMI_CONVERTER_KAI,
          data,
        },
        true
      )
      console.log('ahihi', tx)
      setAmount(0)
    } catch (err) {
      console.log('err', err)
    } finally {
      setLoading(false)
    }
  }

  const approveButtonTitle = useMemo(() => {
    if (approvalState === ApprovalState.INIT_CHECKING) {
      return '...'
    }

    if (approvalState === ApprovalState.APPROVING) {
      return _t(90010, 'Token is approving...')
    }

    if (approvalState === ApprovalState.UNKNOWN) {
      return _t(90060, 'Approve Token')
    }

    return _t(90061, 'Token Approved')
  }, [_t, approvalState])

  const generateSubmitButton = () => {
    if (loading) {
      return <GradientButton name="Loading..." disabled />
    }

    if (!kaiAccount) {
      return <div>Please connect to KAI Wallet!</div>
    }

    return approvalState === ApprovalState.APPROVED ? (
      <GradientButton name="Migrate" disabled={!amount || balanceBN.toNumber() < +amount} onClick={handleSwap} />
    ) : (
      <GradientButton
        onClick={handleApprove}
        disabled={approvalState === ApprovalState.APPROVING || approvalState === ApprovalState.INIT_CHECKING}
        name={approveButtonTitle}
      />
    )
  }

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" alignItems="flex-end">
        <div className="label">Bami V1</div>
        <div className="top-balance">
          <span style={{ marginRight: '0.25rem' }} className="title">
            Balance:
          </span>
          <span className="highlight">{formatNumber(balanceBN.toFixed(18), 8)}</span>
        </div>
      </Flex>
      <Flex className="input-wrapper" alignItems="center">
        <input
          value={amount}
          onChange={(e) => setAmount(parseFloat(e.target.value))}
          type="number"
          placeholder="0.00"
        />
      </Flex>

      <Row justifyContent="center" my="1.5rem">
        <ArrowDown size="2.25rem" />
      </Row>

      <Flex justifyContent="space-between" alignItems="flex-end">
        <div className="label">Bami V2</div>
      </Flex>
      <Flex className="input-wrapper" alignItems="center">
        <input
          value={amount}
          onChange={(e) => setAmount(parseFloat(e.target.value))}
          type="number"
          placeholder="0.00"
        />
      </Flex>

      <Flex justifyContent="center" alignItems="center">
        {generateSubmitButton()}
      </Flex>
    </PageWrapper>
  )
}

export default ConverterPage
